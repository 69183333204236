import styled from 'styled-components';
import { theme, media } from '@styles';

const { colors, fonts } = theme;

export const CoachesSection = styled.section`
  width: 100%;
  height: auto;
  padding: 3rem 0;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`;

export const Card = styled.div`
  border-color: transparent;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background-color: ${colors.hollow};
  text-align: center;
`;

export const CardMedia = styled.div`
  overflow: hidden;
  position: relative;
  text-align: center;

  img {
    width: 100%;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border-style: none;
  }
`;

export const CardContent = styled.div`
  padding: 40px 40px;

  h5 {
    color: ${colors.text};
    margin-bottom: 5px;
    margin-top: 0;
    text-transform: none;
  }

  p {
    font-family: ${fonts.Secondary};
    font-size: 12px;
    line-height: 1;
    margin-bottom: 18px;
    margin-top: 7px;
    color: ${colors.accent};
    text-transform: uppercase;
    letter-spacing: 0 !important;
    font-weight: 400 !important;
  }
`;

const BaseCol = styled.div`
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-left: 30px;
  padding-right: 30px;
`;

export const ImageCol = styled(BaseCol)`
  flex: 0 0 33.333333%;
  max-width: 33.333333%;

  ${media.desktop`
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 2rem;
  `};
`;
export const TextCol = styled(BaseCol)`
  flex: 0 0 66.666667%;
  max-width: 66.666667%;

  ${media.desktop`
    flex: 0 0 100%;
    max-width: 100%;
  `};
`;

export const Title = styled.h4`
  color: ${colors.text};
  font-size: 32px;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0.9em;
  font-family: ${fonts.Secondary};
  font-weight: 400;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  word-break: break-word;
`;
