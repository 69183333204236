import React from 'react';
import { graphql } from 'gatsby';
import SEO from '@components/seo';
import BreadCrumb from '@components/breadcrumb';
import Coaches from '@views/coaches';

const RenderBody = ({ doc }) => {
  const coach = doc.coach;

  return (
    <>
      <SEO
        title="Coaches"
        uri="coaches"
        desc="Our smart and dedicated coaches."
      />
      <BreadCrumb name="Coaches" />
      <Coaches data={coach} />
    </>
  );
};

export default ({ data }) => {
  const doc = data.prismic.allCoachs.edges.slice(0, 1).pop();

  if (!doc) return null;
  return <RenderBody doc={doc.node} />;
};

export const query = graphql`
  {
    prismic {
      allCoachs {
        edges {
          node {
            coach {
              image
              name
              position
              text
            }
          }
        }
      }
    }
  }
`;
